body {
  margin: 0;
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.crisislab-sensor-marker {
  width: 15px;
  height: 15px;
  /* border: 2px solid black; */
  border-radius: 4px;
  cursor: pointer !important;
  background-color: #5ecaeb;
  border: 2px solid black;
}

.crisislab-sensor-marker.online {
  background-color: #157f1f;
}
.crisislab-sensor-marker.offline {
  background-color: #d00000;
}
